/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-05-27 11:29:40
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 14:28:02
 * @Description: 
 */
import request from '@/utils/request'

export function setConfig(data) {
  return request({
    url: '/work/shop/setConfig',
    method: 'post',
    data,
  })
}
export function setSmsInfo(data) {
  return request({
    url: '/work/shop/setOrderRoomSms',
    method: 'post',
    params: data,
  })
}
export function getConfig() {
  return request({
    url: '/work/shop/getConfig',
    method: 'get',
  })
}

// 规格设置相关
export const roundSetting = {
  list: params => request.get(`/work/holiday/list?page=${params.page}`),
  save: params => request.post('/work/holiday/add', params),
  delete: params => request.post('/work/holiday/del', params)
}