<!--
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-11-13 04:27:38
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-12-10 14:40:08
 * @Description: 
-->
<template>
	<div class="container">
		<div class="section-list">
			<a-row :gutter="24">
				<a-col :span="4" :offset="20">
					<a-space class="flex justify-end mb-5">
						<a-button type="primary" ghost>删除</a-button>
						<a-button
							type="primary"
							@click="
								roundVisible = true
								roundTitle = '新增二轮日期'
							">
							新增
						</a-button>
					</a-space>
				</a-col>
				<a-col :span="24">
					<a-table :columns="columns" :data-source="list" :pagination="pagination" :loading="loading" :row-selection="rowSelection" rowKey="id" @change="handleTableChange">
						<template #action="record">
							<a-space :size="10">
								<a-button class="table-action-btn" type="primary" size="small" icon="edit" @click="onHandleListItem(record, 1)">编辑</a-button>
								<a-popconfirm title="确定要删除该二轮日期吗?" ok-text="确定" cancel-text="取消" @confirm="onHandleListItem(record, 0)">
									<a-button class="table-action-btn" type="primary" size="small" icon="delete" ghost>删除</a-button>
								</a-popconfirm>
							</a-space>
						</template>
					</a-table>
				</a-col>
			</a-row>
		</div>
		<a-modal
			width="600px"
			:visible.sync="roundVisible"
			:title="roundTitle"
			@cancel="
				$refs.round.clearValidate()
				roundVisible = false
				roundForm = {}
			"
			@ok="onSaveRound">
			<a-form-model ref="round" :model="roundForm" :label-col="{span: 6}" :wrapper-col="{span: 16}">
				<a-form-model-item
					prop="date"
					label="二轮日期"
					:rules="{
						required: true,
						message: '日期不能为空'
					}">
					<a-date-picker :disabled="roundTitle == '编辑二轮日期' ? true : false" v-model="roundForm.date" @change="onChangeDate" />
				</a-form-model-item>
				<a-form-model-item prop="mark" label="二轮名称">
					<a-input v-model="roundForm.mark" placeholder="请输入二轮名称" />
				</a-form-model-item>
				<a-form-model-item
					prop="meal_segment_value"
					label="二轮餐段"
					:rules="{
						required: true,
						message: '至少选择一个餐段'
					}">
					<a-checkbox-group :options="options" v-model="roundForm.meal_segment_value" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import _ from 'lodash'
import {getConfig, roundSetting} from '@/api/personalization'

export default {
	name: 'RoundSetting',
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id'
				},
				{
					title: '二轮日期',
					dataIndex: 'date'
				},
				{
					title: '二轮名称',
					dataIndex: 'mark'
				},
				{
					title: '应用餐段',
					dataIndex: 'meal_segment',
					customRender: col => {
						if (col.length <= 0) return '无'

						let tmpMealSegment = ''
						this.mealSegment.forEach((item, index) => {
							col.forEach((item_child, index_child) => {
								if (index === index_child && item.open && item_child === 2) {
									tmpMealSegment += item.name + ','
								}
							})
						})

						return tmpMealSegment === '' ? '暂无' : tmpMealSegment
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action'
					}
				}
			],
			list: [],
			loading: false,
			mealSegment: [],
			options: [
				{
					label: '早餐',
					value: 0
				},
				{
					label: '午餐',
					value: 1
				},
				{
					label: '晚餐',
					value: 2
				},
				{
					label: '夜宵',
					value: 3
				}
			],
			pagination: {
				current: 1,
				defaultPageSize: 10,
				showTotal: total => `共${total}条`
			},
			roundForm: {
				date: '',
				mark: '',
				meal_segment: [],
				meal_segment_value: []
			},
			roundVisible: false,
			roundTitle: ''
		}
	},
	async mounted() {
		// 从店铺设置中获取餐段数据
		let tmpMealSegment = await this.fetchMealSegmentList()
		if (tmpMealSegment.length) {
			this.mealSegment = _.cloneDeep(tmpMealSegment)

			if (this.mealSegment.length) {
				// 处理餐段数据
				let tmpOptions = []
				this.mealSegment.forEach((item, index) => {
					if (item.open) {
						tmpOptions.push({
							label: item.name,
							value: index
						})
					}
				})

				this.options = _.cloneDeep(tmpOptions)
			}
		}

		this.fetchRoundList()
	},
	computed: {
		rowSelection() {
			return {
				onChange: (selectedRowKeys, selectedRows) => {
					console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
				},
				getCheckboxProps: record => ({
					props: {
						name: record.name
					}
				})
			}
		}
	},
	methods: {
		/**
		 * @description: 二轮日期选择
		 * @return {*}
		 */
		onChangeDate(date, dateString) {
			this.roundForm.date = dateString
		},

		/**
		 * @description: 编辑/新增保存二轮日期
		 * @return {*}
		 */
		onSaveRound() {
			this.$refs.round.validate().then(async () => {
				this.$message.loading({
					content: '保存中',
					key: 'round'
				})

				let tmpParams = _.cloneDeep(this.roundForm)
				// 格式化餐段数据
				if (tmpParams.meal_segment_value.length) {
					let tmpMealSegment = []
					this.mealSegment.forEach((item, index) => {
						if (tmpParams.meal_segment_value.includes(index)) {
							tmpMealSegment.push(2)
						} else {
							tmpMealSegment.push(1)
						}
					})

					tmpParams.meal_segment = tmpMealSegment

					if (tmpParams.meal_segment_value) delete tmpParams.meal_segment_value
				}

				let {code} = await roundSetting.save(tmpParams)

				if (code === 0) {
					this.$message.success({
						content: '保存成功',
						key: 'round'
					})

					this.roundVisible = false
					this.roundForm = {}

					this.fetchRoundList()
				}
			})
		},

		/**
		 * @description: 获取餐段列表
		 * @return {*}
		 */
		fetchMealSegmentList() {
			this.loading = true

			return new Promise(async resolve => {
				let {code, data} = await getConfig()

				if (code === 0) {
					resolve(data.meal_segment)
				}
			})
		},

		async fetchRoundList(page = 1) {
			this.loading = true

			let {code, data} = await roundSetting.list({page})

			if (code === 0) {
				const pagination = {...this.pagination}
				pagination.total = data.total
				this.pagination = pagination
				this.list = data.data
				this.loading = false
			}
		},

		/**
		 * @description: 翻页
		 * @return {*}
		 * @param {*} pagination
		 */
		handleTableChange(pagination) {
			const pager = {...this.pagination}
			pager.current = pagination.current
			this.pagination = pager
			this.fetchRoundList(pagination.current)
		},

		/**
		 * @description: 编辑/删除节假日
		 * @return {*}
		 */
		async onHandleListItem(record, type) {
			if (type === 1) {
				if (record.meal_segment.length) {
					record.meal_segment_value = record.meal_segment.map((item, index) => {
						if (item === 2) return index
					})
				}

				this.roundVisible = true
				this.roundTitle = '编辑二轮日期'
				this.roundForm = _.cloneDeep(record)
			} else {
				this.loading = true

				let {code} = await roundSetting.delete({
					date: record.date
				})

				if (code === 0) {
					this.$message.success('删除成功')

					this.fetchRoundList()
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	max-width: 100% !important;

	.section-list {
		padding: 22px 20px;
		width: auto;
		height: 100%;
		min-height: calc(100vh - 150px);
		background-color: #fff;
		border-radius: 2px;
	}
}

.table-action-btn {
	font-size: 12px;
}
</style>
